<template>
  <v-alert
    prominent
    outlined
    :type="alertType"
    v-if="step && step.mensagem && step.mensagem[show]"
  >
    <v-row class="text-center">
      <v-col class="grow font-weight-bold pt-5">
        {{ step.mensagem[show] }}
      </v-col>
      <v-col class="shrink" v-if="show == 'sucesso'">
        <FormButton type="warning" @click="onClick()" text="Clique aqui" />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import FormButton from '@/components/form/FormButton'

export default {
  name: 'DeploymentMessage',
  components: {
    FormButton
  },
  props: {
    show: {
      type: String,
      default: '',
      required: true
    },
    step: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  computed: {
    alertType() {
      return this.show == 'sucesso' ? 'warning' : 'error'
    }
  },
  methods: {
    onClick() {
      this.$router.push(`auto-implantacao`)
    }
  }
}
</script>
