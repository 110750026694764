<template>
  <v-select
    v-model="model"
    :placeholder="placeholder || ''"
    :clearable="clearable"
    :error-messages="errors"
    :messages="messages"
    :multiple="multiple"
    :disabled="disabled"
    :readonly="readonly"
    :dark="darkEnabled"
    :items="options"
    :dense="dense"
    :label="label"
    :solo="solo"
    :hint="hint"
    outlined
    rounded
    flat
    @input="onChange"
    @blur="onBlur"
  >
    <template v-slot:append>
      <slot name="append">
        <v-tooltip top v-if="help">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">far fa-question-circle</v-icon>
          </template>
          {{ help }}
        </v-tooltip>
      </slot>
    </template>
    <template v-slot:selection="{ item }">
      <div v-if="item.icon">
        <i :class="item.icon"></i>
      </div>
      <div v-else class="card">
        {{ item.text }}
      </div>
    </template>
    <template v-slot:item="{ item }">
      <slot name="option" :option="item">
        <div v-if="item.icon">
          <i :class="item.icon"></i>
          <span style="font-size: 9px; padding-left: 5px; color: #a9a9a9">
            {{ item.text }}
          </span>
        </div>
        <div v-else>
          {{ item.text }}
        </div>
      </slot>
    </template>
  </v-select>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormSelect',
  props: {
    options: {
      type: Array,
      default: () => [],
      required: false
    },
    value: {
      type: [Array, String, Object],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    messages: {
      type: String,
      default: '',
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    help: {
      type: String,
      default: '',
      required: false
    }
  },
  data: () => ({
    visibled: false,
    model: '',
    touched: false
  }),
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
        }
      }
    },
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (!this.validation.required) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        return errorsMess
      }
      return []
    }
  },
  created() {
    this.model = this.value
  },
  methods: {
    onChange() {
      this.$emit('change', this.model)
      this.$emit('input', this.model)
      this.checkValidation()
    },
    onBlur() {
      this.$emit('blur', this.model)
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    }
  }
}
</script>

<style scoped>
.card {
  display: flex;
  align-items: center;
  background-color: #e0e0e0;
  font-size: 14px;
  height: 32px;
  margin: 4px;
  padding: 0 12px;
  border-radius: 16px;
}
</style>
