<template>
  <v-row>
    <v-col cols="12" md="5">
      <FormSelect
        label="Layout preview"
        class="px-md-8"
        v-model="cardProdutoPreview.exibir"
        :options="arrayCards"
      />
      <h4 class="text-center">Pré-visualização</h4>
      <v-card v-if="cardProdutoPreview" class="mt-5 mx-auto" max-width="320px">
        <v-card-title
          class="d-block text-center"
          :style="getStyleNP"
          v-if="cardProdutoPreview.nome"
          v-html="cardProdutoPreview.nome"
        />
        <v-card-title
          class="d-block text-center"
          :style="getStyleNP"
          v-else
          v-text="'Café 3 Corações - embalagem de 300g - CX com 3 UN'"
        />
        <v-card-text class="text-center">
          <div
            v-show="cardProdutoPreview.exibir === 'POLITICA_DESCONTO'"
            class="promo-flag"
          >
            <v-img contain src="@/assets/tema/img-prod-descont-flag.png" />
          </div>
          <v-img
            contain
            class="mt-n12 mb-n12"
            src="@/assets/tema/img-prod-commerce.png"
          />
          <div v-if="showCardAtacado">
            <span :style="getStylePA">
              {{ cardProdutoPreview.preco || 'R$ 3 UN' }}
            </span>
            <p class="style-pa" :style="getStylePA">
              A partir de 3 UN
            </p>
          </div>
          <p v-if="!showCardAtacado" class="style-po" :style="getStylePO">
            {{ cardProdutoPreview.precoOriginal || 'R$ 6 UN' }}
          </p>
          <span :style="getStylePV">
            {{ cardProdutoPreview.precoVarejo || 'R$ 6 UN' }}
          </span>
          <div
            v-show="cardProdutoPreview.exibir === 'PRECIFICACAO_VARIAVEL'"
            class="variavel-flag"
          >
            <v-img contain src="@/assets/tema/img-prec-variavel-flag.png" />
          </div>
          <div :style="getStyleME" v-html="clearMessageUsingSanitaze()"></div>
          <div
            class="width-full mt-5"
            v-if="showIndisponivel && !showSobDemanda"
          >
            Produto não disponível no momento
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div
            v-if="!showIndisponivel && !showSobDemanda"
            class="width-full style-ba"
            :style="getStyleBA"
          >
            <span>Adicionar ao carrinho</span>
          </div>
          <div
            v-if="showIndisponivel && !showSobDemanda"
            class="width-full style-bi"
            :style="getStyleBI"
          >
            <span>Avisar-me quando estiver disponível</span>
          </div>
          <div
            v-if="showSobDemanda"
            class="width-full style-bs"
            :style="getStyleBS"
          >
            <span>{{
              cardProdutoPreview.btnSobDemanda ||
                'Sob Demanda - Entrar em contato'
            }}</span>
          </div>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="12" md="7">
      <div v-for="(d, key, i) in cardProduto.campos" :key="key">
        <v-divider v-if="i != 0" class="my-5" />
        <h4 class="pa-1">{{ d.label }}</h4>
        <v-row no-gutters>
          <v-col cols="3">
            <h5 class="px-1 text-center" v-if="d.labels && d.labels.bg">
              {{ d.labels.bg }}
            </h5>
            <v-menu
              offset-y
              v-if="d.labels && d.labels.bg"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <FormButton
                  :rounded="false"
                  outlined
                  class="border-button"
                  :color="cardProduto.meta_info[d.key].bgColor"
                  full
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    v-on="on"
                    size="30"
                    class="mr-2"
                    :color="cardProduto.meta_info[d.key].bgColor"
                    >fa-circle</v-icon
                  >
                  <span class="font-weight-bold grey--text text--darken-2">
                    {{ cleanHexColor(cardProduto.meta_info[d.key].bgColor) }}
                  </span>
                </FormButton>
              </template>
              <v-card :color="cardProduto.meta_info[d.key].bgColor">
                <v-card-text class="pa-1">
                  <v-color-picker
                    mode="hexa"
                    :value="cleanHexColor(cardProduto.meta_info[d.key].bgColor)"
                    @input="updateColor($event, d.key, 'bgColor')"
                    dot-size="20"
                    flat
                    hide-mode-switch
                    hide-sliders
                  ></v-color-picker>
                </v-card-text>
              </v-card>
            </v-menu>
            <h5 class="pt-1 px-1 text-center" v-if="d.labels && d.labels.cor">
              {{ d.labels.cor }}
            </h5>
            <h5 class="pt-1 px-1 text-center" v-else>Texto</h5>
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <FormButton
                  :rounded="false"
                  outlined
                  class="border-button"
                  :color="cardProduto.meta_info[d.key].cor"
                  full
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    v-on="on"
                    size="30"
                    class="mr-2"
                    :color="cardProduto.meta_info[d.key].cor"
                    >fa-circle</v-icon
                  >
                  <span class="font-weight-bold grey--text text--darken-2">
                    {{ cleanHexColor(cardProduto.meta_info[d.key].cor) }}
                  </span>
                </FormButton>
              </template>
              <v-card :color="cardProduto.meta_info[d.key].cor">
                <v-card-text class="pa-1">
                  <v-color-picker
                    mode="hexa"
                    :value="cleanHexColor(cardProduto.meta_info[d.key].cor)"
                    @input="updateColor($event, d.key, 'cor')"
                    dot-size="20"
                    flat
                    hide-mode-switch
                  ></v-color-picker>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="9" class="d-flex align-end">
            <FormButton
              class="mx-2"
              :rounded="false"
              outlined
              @click="toggleField(d.key, 'bold')"
              :color="getActiveColor(d.key, 'bold')"
            >
              <v-icon size="17">fa-bold</v-icon>
            </FormButton>
            <FormButton
              :rounded="false"
              outlined
              @click="toggleField(d.key, 'italic')"
              :color="getActiveColor(d.key, 'italic')"
            >
              <v-icon size="17">fa-italic</v-icon>
            </FormButton>
            <v-select
              prepend-icon="fa-mobile"
              :items="arrayNumbers"
              v-model="cardProduto.meta_info[d.key].fontSize.app"
              dense
              outlined
              class="mb-n7 mx-3"
            ></v-select>
            <v-select
              prepend-icon="fa-chrome"
              :items="arrayNumbers"
              v-model="cardProduto.meta_info[d.key].fontSize.commerce"
              dense
              outlined
              class="mb-n7"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import { TEMA_CARDS } from '@/store/actions/tema'
import DOMPurify from 'dompurify'
import { mapGetters } from 'vuex'

export default {
  name: 'ProductCardPreview',
  components: {
    FormSelect,
    FormButton
  },
  props: {
    cardProdutoPreview: {
      type: Object,
      default: null,
      required: true
    },
    cardProduto: {
      type: Object,
      default: null,
      required: true
    }
  },
  data: () => ({
    arrayNumbers: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
  }),
  computed: {
    ...mapGetters(['getFornecedorTag']),
    arrayCards() {
      return TEMA_CARDS
    },
    showCardAtacado() {
      return this.cardProdutoPreview.exibir === 'ATACADO'
    },
    showIndisponivel() {
      return this.cardProdutoPreview.exibir === 'INDISPONIVEL'
    },
    showSobDemanda() {
      return this.cardProdutoPreview.exibir === 'SOB_DEMANDA'
    },
    getMedaInfo() {
      return this.cardProduto.meta_info || {}
    },
    getStyleNP() {
      return this.getStyle('nome_produto')
    },
    getStylePV() {
      return this.getStyle('preco_venda')
    },
    getStylePO() {
      return this.getStyle('preco_original')
    },
    getStylePA() {
      return this.getStyle('preco_atacado')
    },
    getStyleME() {
      return this.getStyle('msg_extra')
    },
    getStyleBA() {
      return this.getStyle('btn_adicionar')
    },
    getStyleBI() {
      return this.getStyle('btn_availability_alert')
    },
    getStyleBS() {
      return this.getStyle('btn_sob_demanda')
    }
  },
  methods: {
    getStyle(key) {
      if (typeof key !== 'string') return
      if (!this.getMedaInfo[key]) return
      const isBold = this.getMedaInfo[key].bold === 'true' ? true : false
      const isItalic = this.getMedaInfo[key].italic === 'true' ? true : false
      return {
        'font-weight': isBold ? 'bolder' : '',
        'font-style': isItalic ? 'italic' : '',
        'font-size': this.getFontSize(
          this.getMedaInfo[key].fontSize.commerce,
          'COMMERCE'
        ),
        color: this.getMedaInfo[key].cor || '',
        'background-color': this.getMedaInfo[key].bgColor || ''
      }
    },
    getActiveColor(key, field) {
      return this.cardProduto.meta_info[key][field] === 'true' ? 'dark' : 'grey'
    },
    getFontSize(value, application) {
      const defaultFontSize = application === 'APP' ? '1' : '1.15'
      let fontSize = (defaultFontSize / 5) * value
      const ext = 'em'
      return `${fontSize}${ext}`
    },
    clearMessageUsingSanitaze() {
      return DOMPurify.sanitize(this.cardProdutoPreview.mensagemExtra)
    },
    toggleField(key, field) {
      this.$emit('toggle-field', key, field)
    },
    cleanHexColor(hexColor) {
      const cleanColor =
        hexColor.length === 9 ? hexColor.substring(0, 7) : hexColor
      return cleanColor || '#000000'
    },
    updateColor(color, key, field) {
      const cleanColor = color.length === 9 ? color.substring(0, 7) : color
      this.$set(this.cardProduto.meta_info[key], field, cleanColor)
    }
  }
}
</script>

<style lang="scss" scoped>
.style-po {
  opacity: 0.8;
  transform: scale(0.8);
  text-decoration: line-through;
}
.style-pa {
  opacity: 0.8;
  transform: scale(0.8);
}
.width-full {
  width: 100%;
}
.style-ba {
  border: solid 1px #ccc;
  padding: 5px;
  text-align: center;
}
.style-bi {
  border: solid 1px #ccc;
  background: #ccc;
  padding: 5px;
  text-align: center;
}
.style-bs {
  border: solid 1px #ccc;
  padding: 5px;
  text-align: center;
}
.variavel-flag {
  position: absolute;
  left: -9px;
  top: 330px;
}
.promo-flag {
  position: absolute;
  left: -9px;
  top: 75px;
}
.border-button {
  border: 1px solid #7e7675;
}
</style>
