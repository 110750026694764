<template>
  <div class="page">
    <v-alert
      v-if="
        !getFornecedor.publicado &&
          showWizard == '' &&
          stepWizard.mensagem &&
          stepWizard.mensagem.falha
      "
      type="error"
      outlined
      class="font-weight-bold"
    >
      {{ stepWizard.mensagem.falha }}
    </v-alert>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainelPortifolio)"
    >
      <h4 v-if="moduloFornecedorAtivo(modulosCommerce)">
        Tema do e-commerce Web
      </h4>
      <h4 v-else>Tema do Site</h4>
      <LineDivider></LineDivider>
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <h4 class="mt-5 mb-3 text-start">Logo e-commerce</h4>
          <DragDropUploader
            :class="borderRed"
            :upload-url="endpointUploadUrl"
            :upload-multiple="false"
            initial-icon-class="fas fa-cloud-upload-alt"
            :params="{ path: 'logos/commerce' }"
            @init-upload="onInitUpload"
            @upload-success="onUploadSuccess"
          >
          </DragDropUploader>
          <small class="font-weight-bold"
            >Selecione ou arraste sua imagem de logo.</small
          >
        </v-col>
        <v-col cols="12" md="3" class="border-left">
          <div
            class="mx-auto border-img mt-10 pa-2 d-flex align-center justify-center"
          >
            <v-img
              v-if="urlLogo && !isLoadingImg"
              max-width="200px"
              :src="`${urlLogo}?${Date.now()}`"
            />
            <v-progress-circular
              v-else
              :size="100"
              :width="7"
              color="primario"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainelPortifolio)"
    >
      <h4>Cores e-commerce</h4>
      <LineDivider></LineDivider>
      <v-row justify="space-around">
        <v-col class="text-start" cols="12" md="2">
          <h4 class="pa-1">Cor Primária</h4>
          <v-color-picker
            class="border-color-picker"
            :class="borderRed"
            mode="hexa"
            dot-size="20"
            flat
            hide-mode-switch
            v-model="tema.primaryColor"
          ></v-color-picker>
        </v-col>
        <v-col class="text-start" cols="12" md="2">
          <h4 class="pa-1">Cor Secundária</h4>
          <v-color-picker
            class="border-color-picker"
            :class="borderRed"
            mode="hexa"
            dot-size="20"
            flat
            hide-mode-switch
            v-model="tema.secondaryColor"
          ></v-color-picker>
        </v-col>
        <v-col class="text-start" cols="12" md="2">
          <h4 class="pa-1">Cor Auxiliar</h4>
          <v-color-picker
            class="border-color-picker"
            :class="borderRed"
            mode="hexa"
            dot-size="20"
            flat
            hide-mode-switch
            v-model="tema.auxiliarColor"
          ></v-color-picker>
        </v-col>
        <v-col class="text-start" cols="12" md="2">
          <h4 class="pa-1">Cor do Texto</h4>
          <v-color-picker
            class="border-color-picker"
            :class="borderRed"
            mode="hexa"
            dot-size="20"
            flat
            hide-mode-switch
            v-model="tema.textColor"
          ></v-color-picker>
        </v-col>
        <v-col class="text-start" cols="12" md="2">
          <h4 class="pa-1">Cor do Texto Auxiliar</h4>
          <v-color-picker
            class="border-color-picker"
            :class="borderRed"
            mode="hexa"
            dot-size="20"
            flat
            hide-mode-switch
            v-model="tema.textAuxiliarColor"
          ></v-color-picker>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainelPortifolio)"
    >
      <h4>Configurações da página web</h4>
      <LineDivider></LineDivider>
      <v-row>
        <v-col cols="12">
          <FormField
            :class="borderRed"
            label="Título da página"
            v-model="configuracao.tituloPagina"
          />
        </v-col>
        <v-col cols="12" md="4" class="text-center">
          <h4 class="mt-5 mb-3 text-start">Ícone da página</h4>
          <DragDropUploader
            :class="borderRed"
            :upload-url="endpointUploadUrl"
            :upload-multiple="false"
            initial-icon-class="fas fa-cloud-upload-alt"
            :params="{ path: 'favicon' }"
            @init-upload="onInitUpload"
            @upload-success="onUploadSuccess"
          >
          </DragDropUploader>
          <small class="font-weight-bold"
            >Selecione ou arraste sua imagem de ícone.</small
          >
        </v-col>
        <v-col cols="12" md="3" class="border-left">
          <div
            class="mx-auto border-img mt-10 pa-2 d-flex align-center justify-center"
          >
            <v-img
              v-if="urlIcon && !isLoadingImg"
              max-width="200px"
              :src="`${urlIcon}?${Date.now()}`"
            />
            <v-progress-circular
              v-else
              class="my-10"
              :size="100"
              :width="7"
              color="primario"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col cols="12">
          <FormSwitch
            v-model="configuracao.habilitarCarregar"
            label="Habilitar botão 'Carregar mais'"
          />
        </v-col>
      </v-row>
      <h4 class="mt-5">Itens de menu com links externos</h4>
      <LineDivider></LineDivider>
      <v-row justify="space-around">
        <v-col cols="12" md="5">
          <FormField label="Descrição do menu" v-model="menu.descricao" />
        </v-col>
        <v-col cols="12" md="5">
          <FormField label="Endereço de destino" v-model="menu.destino" />
        </v-col>
        <v-col cols="12" md="2" class="text-center">
          <FormButton
            type="primario"
            @click="adicionarMenu()"
            text="Adicionar"
          />
        </v-col>
      </v-row>
      <v-row justify="space-around" v-for="(m, i) in menu.menus" :key="i">
        <v-col cols="12" md="5" class="py-0">
          <FormField label="Descrição do menu" readonly v-model="m.descricao" />
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <FormField label="Endereço de destino" readonly v-model="m.destino" />
        </v-col>
        <v-col cols="12" md="2" class="text-center py-0">
          <FormButton type="error" @click="removerMenu(m)" text="Excluir" />
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainelPortifolio)"
    >
      <h4 class="text-start" v-if="moduloFornecedorAtivo(modulosCommerce)">
        Logotipo do Marketplace
      </h4>
      <h4 class="text-start" v-else>Logotipo do Site</h4>
      <LineDivider></LineDivider>
      <v-row>
        <v-col cols="12" md="4" class="text-center">
          <h4 class="mt-5 mb-3 text-start">Logotipo</h4>
          <small>
            Preencha somente se sua empresa fizer parte do nosso Marketplace.
          </small>
          <DragDropUploader
            :class="borderRed"
            class="mt-1"
            :upload-url="endpointUploadUrl"
            :upload-multiple="false"
            initial-icon-class="fas fa-cloud-upload-alt"
            :params="{ path: 'logos' }"
            @init-upload="onInitUpload"
            @upload-success="onUploadSuccess"
          >
          </DragDropUploader>
          <small class="font-weight-bold"
            >Selecione ou arraste sua imagem de logotipo.</small
          >
        </v-col>
        <v-col cols="12" md="3" class="border-left">
          <div
            class="mx-auto border-img mt-10 pa-2 d-flex align-center justify-center"
          >
            <v-img
              v-if="urlLogotipo && !isLoadingImg"
              max-width="200px"
              :src="`${urlLogotipo}?${Date.now()}`"
            />
            <v-progress-circular
              v-else
              class="my-10"
              :size="100"
              :width="7"
              color="primario"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <h4>Apresentação do produto</h4>
      <LineDivider></LineDivider>
      <v-row justify="space-around">
        <v-col cols="12" md="5">
          <FormField
            label="Título do produto"
            v-model="cardProduto.templateTitulo"
          />
          <FormField
            label="Mensagem extra do produto"
            v-model="cardProduto.templateMensagemExtra"
          />
          <FormSelect
            label="Quantidade de casas decimais"
            v-model="cardProduto.templateQtdeDecimais"
            :options="arrayDecimais"
          />
          <h4>Informações disponíveis</h4>
          <v-list dense>
            <v-list-item v-for="(tc, i) in tagsCard" :key="i">
              <code
                class="pr-2 mr-3 text-no-wrap"
                v-text="tc.tag"
                @click="copyToClipboard(tc.tag)"
              ></code>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="font-weight-bold text-truncate">
                    - {{ tc.text }}</span
                  >
                </template>
                {{ tc.text }}
              </v-tooltip>
            </v-list-item>
          </v-list>
          <h4>Exemplo</h4>
          <p v-text="exemploTemplateProduto" class="red--text"></p>
          <h4>Será apresentado</h4>
          <p v-text="exemploTemplateProdutoProcessado"></p>
        </v-col>
        <v-col cols="12" md="7" class="border-left">
          <ProductCardPreview
            :card-produto-preview="cardProdutoPreview"
            :card-produto="cardProduto"
            @toggle-field="toggleField"
          />
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosCommerce)"
    >
      <h4>
        Apresentação da página de cupom de recomendação
      </h4>
      <LineDivider></LineDivider>
      <v-row justify="space-around">
        <v-col cols="12" md="8">
          <v-row no-gutters>
            <v-col cols="12" md="5" class="text-center">
              <h4 class="mb-3 text-start">Logotipo</h4>
              <DragDropUploader
                :class="borderRed"
                :upload-url="endpointUploadUrl"
                :upload-multiple="false"
                initial-icon-class="fas fa-cloud-upload-alt"
                :params="{ path: 'cupom' }"
                @init-upload="onInitUpload"
                @upload-success="onUploadSuccess"
              >
              </DragDropUploader>
              <small class="font-weight-bold"
                >Selecione ou arraste sua imagem da página de cupom.</small
              >
            </v-col>
            <v-col cols="12" md="7" class="pl-3">
              <h4>Texto</h4>
              <Simplemde
                v-model="cupomRecomendacao.texto"
                @input="updateCupomTexto"
              />
              <small>
                Para aprender mais sobre o formato do conteúdo veja as
                <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
                  >dicas de formatação</a
                >.
              </small>
            </v-col>
          </v-row>
          <FormField
            class="mt-5"
            label="Texto da mensagem de compartilhamento"
            v-model="cupomRecomendacao.mensagem"
            @input="updateCupomMensagem"
            help="A mensagem será compartilhada exatamente como foi demostrada na previsualização"
          />
          <h4>Informações disponíveis</h4>
          <v-list dense>
            <v-list-item>
              <code
                @click="copyToClipboard('{{ desconto_cupom }}')"
                class="pr-2 mr-3"
                v-text="'{{ desconto_cupom }}'"
              ></code>
              <span class="font-weight-bold"> - Desconto do cupom</span>
            </v-list-item>
            <v-list-item>
              <code
                @click="copyToClipboard('{{ codigo_desconto }}')"
                class="mr-3"
                v-text="'{{ codigo_desconto }}'"
              ></code>
              <span class="font-weight-bold"> - Código do cupom</span>
            </v-list-item>
          </v-list>
          <h4>Exemplo</h4>
          <p v-text="exemploTemplateCupom" class="red--text"></p>
          <h4>Será apresentado</h4>
          <p v-text="exemploTemplateCupomProcessado"></p>
        </v-col>
        <v-col cols="12" md="3" class="border-left">
          <h4 class="text-center">Pré-visualização</h4>
          <v-card class="ma-2 mx-auto" max-width="320px">
            <v-card-title class="primario py-6" />
            <v-card-text>
              <div class="mx-auto border-img-cupom my-2 text-center">
                <v-img
                  v-if="urlCupom && !isLoadingImg"
                  max-width="200px"
                  class="mx-auto"
                  :src="`${urlCupom}?${Date.now()}`"
                />
                <v-progress-circular
                  v-else
                  class="my-10"
                  :size="100"
                  :width="7"
                  color="primario"
                  indeterminate
                ></v-progress-circular>
              </div>
              <VueMarkdown
                :source="cupomRecomendacaoPreview.texto"
                :watches="['show', 'html', 'breaks', 'linkify', 'emoji']"
                :show="true"
                :html="true"
                :breaks="true"
                :linkify="true"
                :emoji="true"
                :typographer="true"
              />
              <div class="text-center pa-3 font-weight-bold">
                {{ exemploCodigoCupomProcessado }}
              </div>
              <FormButton
                type="primario"
                class="font-weight-bold mt-3"
                text="Compartilhar"
                :rounded="false"
                full
              >
                <v-icon class="ml-3">fa-share-alt</v-icon>
              </FormButton>
            </v-card-text>
            <v-card-actions></v-card-actions>
          </v-card>
          <v-card max-width="320px" class="mt-5 mx-auto">
            <v-img
              contain
              max-width="320px"
              src="@/assets/tema/preview-whatsapp.png"
            >
              <span class="bubble"
                >{{ cupomRecomendacaoPreview.mensagem }}
              </span>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainelPortifolio)"
    >
      <v-row no-gutters>
        <h4>Rodapé</h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.rodape = !panels.rodape"
        >
          <v-icon v-if="!panels.rodape">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.rodape">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.rodape" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <v-row no-gutters>
        <h4>Informações adicionais</h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.infoAdicionais = !panels.infoAdicionais"
        >
          <v-icon v-if="!panels.infoAdicionais">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.infoAdicionais">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.infoAdicionais" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <v-row no-gutters>
        <h4>Termos de uso</h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.termosUso = !panels.termosUso"
        >
          <v-icon v-if="!panels.termosUso">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.termosUso">
        <LineDivider></LineDivider>
        <v-row>
          <v-col cols="12" md="6">
            <p>{{ termosUso.info }}</p>
          </v-col>
          <v-col cols="12" md="6" class="text-end">
            <FormButton
              v-if="!termosUso.isUpdating"
              type="primario"
              @click="termosUso.isUpdating = !termosUso.isUpdating"
              text="Editar e criar nova versão dos termos"
            />
            <FormButton
              v-else
              type="error"
              @click="termosUso.isUpdating = !termosUso.isUpdating"
              text="Descartar edições e manter versão"
            />
          </v-col>
        </v-row>
        <template v-if="termosUso.isUpdating">
          <small
            v-text="
              `Não insira tags como <html> <header> <body> ou <script> pois isso pode provocar problemas de exibição`
            "
            class="red--text"
          >
          </small>
          <Simplemde v-model="termosUso.conteudo" />
          <small>
            Para aprender mais sobre o formato do conteúdo veja as
            <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
              >dicas de formatação</a
            >.
          </small>
        </template>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosFull)"
    >
      <v-row no-gutters>
        <h4>Política de privacidade</h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.politicaPrivacidade = !panels.politicaPrivacidade"
        >
          <v-icon v-if="!panels.politicaPrivacidade">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.politicaPrivacidade">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.politicaPrivacidade" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommerce)"
    >
      <v-row no-gutters>
        <h4>
          Instruções para o cliente na finalização de pedido
        </h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.instrucoesPedido = !panels.instrucoesPedido"
        >
          <v-icon v-if="!panels.instrucoesPedido">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.instrucoesPedido">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.instrucoesPedido" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainel)"
    >
      <v-row no-gutters>
        <h4>
          Instruções para o cliente no login
        </h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.instrucaoLogin = !panels.instrucaoLogin"
        >
          <v-icon v-if="!panels.instrucaoLogin">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.instrucaoLogin">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.instrucaoLogin" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainel)"
    >
      <v-row no-gutters>
        <h4>
          Instruções para o cliente no cadastro
        </h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.instrucaoCadastro = !panels.instrucaoCadastro"
        >
          <v-icon v-if="!panels.instrucaoCadastro">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.instrucaoCadastro">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.instrucaoCadastro" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommercePainel)"
    >
      <v-row no-gutters>
        <h4>
          Mensagem para sobre cadastro em análise
        </h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.msgCadastroAnalise = !panels.msgCadastroAnalise"
        >
          <v-icon v-if="!panels.msgCadastroAnalise">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.msgCadastroAnalise">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.msgCadastroAnalise" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <Panel
      lineColor="var(--primary-color)"
      class="text-start mt-5"
      v-if="moduloFornecedorAtivo(modulosOrcamentoCommerce)"
    >
      <v-row no-gutters>
        <h4>
          Instruções para o cliente após a submissão do pedido
        </h4>
        <FormButton
          class="ml-auto"
          icon
          type="primario"
          @click="panels.instrucoesSubmissao = !panels.instrucoesSubmissao"
        >
          <v-icon v-if="!panels.instrucoesSubmissao">fa-plus</v-icon>
          <v-icon v-else>fa-minus</v-icon>
        </FormButton>
      </v-row>
      <template v-if="panels.instrucoesSubmissao">
        <LineDivider></LineDivider>
        <Simplemde v-model="form.instrucoesSubmissao" />
        <small>
          Para aprender mais sobre o formato do conteúdo veja as
          <a href="https://pt.wikipedia.org/wiki/Markdown" target="_blank"
            >dicas de formatação</a
          >.
        </small>
      </template>
    </Panel>
    <v-row>
      <v-col class="text-end">
        <FormButtonGroup align="end" spaced>
          <FormButton
            class="my-3"
            type="secundario"
            @click="onRestaurarTema()"
            text="Restaurar Tema Padrão"
          />
          <FormButton
            :loading="isLoading"
            class="my-3"
            type="primario"
            @click="onSubmitTema()"
            text="Salvar"
          />
        </FormButtonGroup>
      </v-col>
      <v-col cols="12">
        <DeploymentMessage :show="showWizard" :step="stepWizard" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import FormField from '@/components/form/FormField'
import FormSwitch from '@/components/form/FormSwitch'
import ProductCardPreview from '@/components/ProductCardPreview'
import FormSelect from '@/components/form/FormSelect'
import FormButton from '@/components/form/FormButton'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import LineDivider from '@/components/LineDivider'
import DeploymentMessage from '@/components/DeploymentMessage'
import DragDropUploader from '@/components/Drag-drop-uploader'
import { mapGetters } from 'vuex'
import { ADMIN_GET_PASSOS_IMPLANTACAO } from '@/store/actions/admin'
import {
  TEMA_LOAD,
  TEMA_UPDATE_MENU,
  TEMA_LOAD_MENU,
  TEMA_RESTAURAR_MENU,
  TEMA_LOAD_STATIC,
  TAGS_CARD,
  TEMA_TEMPLATE,
  TEMA_UPDATE
} from '@/store/actions/tema'
import { TEXTO_ESTATICO_LOAD } from '@/store/actions/textoEstatico'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import * as _ from 'lodash'
import Simplemde from 'vue-simplemde'
import Mustache from 'mustache'
import VueMarkdown from 'vue-markdown'
import { DECIMAIS } from '@/store/actions/parametros'
import moment from 'moment'

export default {
  name: 'DadosEmpresa',
  components: {
    Panel,
    FormField,
    FormSwitch,
    FormSelect,
    FormButton,
    FormButtonGroup,
    LineDivider,
    DeploymentMessage,
    Simplemde,
    ProductCardPreview,
    VueMarkdown,
    DragDropUploader
  },
  data: () => ({
    panels: {
      rodape: true,
      infoAdicionais: true,
      termosUso: true,
      politicaPrivacidade: true,
      instrucoesPedido: true,
      instrucaoLogin: true,
      instrucaoCadastro: true,
      msgCadastroAnalise: true,
      instrucoesSubmissao: true
    },
    isLoading: false,
    isLoadingImg: true,
    form: {},
    arrayDecimais: DECIMAIS,
    tagsCard: TAGS_CARD,
    tema: {},
    colorPickerActive: 'primary-color',
    configuracao: {},
    textos: {},
    menu: {
      descricao: '',
      destino: '',
      menus: []
    },
    cupomRecomendacao: {},
    cupomRecomendacaoPreview: {},
    cardProdutoPreview: {
      nome: '',
      mensagemExtra: '',
      preco: '',
      precoVarejo: '',
      precoOriginal: '',
      exibir: 'ATACADO',
      btnSobDemanda: ''
    },
    cardProduto: {
      templateTitulo: '',
      templateMensagemExtra: '',
      templateQtdeDecimais: '',
      campos: {},
      meta_info: {}
    },
    datasetTemplate: TEMA_TEMPLATE,
    wizardPath: 'personalizar-loja',
    termosUso: {
      info: '',
      isUpdating: false,
      versao: 0,
      conteudo: ''
    },
    conteudoRodape: '',
    showWizard: '',
    stepWizard: {},
    s3BucketRoot: '',
    modulosFull: [ModulesEnum.TODOS],
    modulosCommerce: [ModulesEnum.WEB_COMMERCE, ModulesEnum.COMMERCE_APP],
    modulosOrcamentoCommerce: [
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP
    ],
    modulosOrcamentoCommercePainel: [
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.PAINEL_CLIENTE
    ],
    modulosOrcamentoCommercePainelPortifolio: [
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP,
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.PORTFOLIO_WEB
    ]
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedor',
      'getFornecedores',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    endpointUploadUrl() {
      return `/api/v4/theme/upload/image/${this.getFornecedorId}`
    },
    urlLogo() {
      return `${this.s3BucketRoot}/fornec-space/logos/commerce/${this.getFornecedorId}`
    },
    urlIcon() {
      return `${this.s3BucketRoot}/fornec-space/favicon/${this.getFornecedorId}`
    },
    urlLogotipo() {
      return `${this.s3BucketRoot}/fornec-space/logos/${this.getFornecedorId}`
    },
    urlCupom() {
      return `${this.s3BucketRoot}/fornec-space/cupom/${this.getFornecedorId}`
    },
    exemploTemplateCupom() {
      return `Presenteie seus amigos com {{ desconto_cupom }} de desconto por meio do código {{ codigo_desconto }} Sempre que um amigo utilizar este código em sua primeira compra você ganhará o mesmo desconto de volta nas suas próximas compras! Quanto mais amigos utilizarem seu código mais desconto você ganhará!`
    },
    exemploTemplateCupomProcessado() {
      return Mustache.render(this.exemploTemplateCupom, this.datasetTemplate)
        .replace(/([\s\-\/]+)$/g, '')
        .replace(/([\s\-\/] [\s\-\/])/g, '')
    },
    exemploTemplateProduto() {
      return '{{nome}} - {{descricao_embalagem}} - {{descricao_auto_multiplo}}'
    },
    exemploTemplateProdutoProcessado() {
      return Mustache.render(this.exemploTemplateProduto, this.datasetTemplate)
        .replace(/([\s\-\/]+)$/g, '')
        .replace(/([\s\-\/] [\s\-\/])/g, '')
    },
    exemploCodigoCupom() {
      return '{{ codigo_desconto }}'
    },
    exemploCodigoCupomProcessado() {
      return Mustache.render(this.exemploCodigoCupom, this.datasetTemplate)
        .replace(/([\s\-\/]+)$/g, '')
        .replace(/([\s\-\/] [\s\-\/])/g, '')
    },
    styleWhatsapp() {
      const botton = 125 + this.cupomRecomendacaoPreview.mensagem.length * 0.35
      return { bottom: `${botton}px` }
    },
    borderRed() {
      return !this.getFornecedor.publicado && this.showWizard == ''
        ? 'border-red'
        : ''
    }
  },
  methods: {
    getPassosImplantacao() {
      this.isLoading = true
      this.$store
        .dispatch(ADMIN_GET_PASSOS_IMPLANTACAO, {
          execute: `${true}`,
          step: this.wizardPath
        })
        .then(data => {
          this.isLoading = false
          if (data && data[0]) {
            this.stepWizard = data[0]
          }
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            'Falha ao buscar os passos de implantação'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    getDataTema() {
      const temaDefault = {
        logo: false,
        primaryColor: '#436bac',
        secondaryColor: '#203e6c',
        auxiliarColor: '#e2a929',
        textAuxiliarColor: '#ffffff',
        textColor: '#ffffff'
      }
      this.isLoading = true
      this.$store
        .dispatch(TEMA_LOAD, false)
        .then(configuracao => {
          this.tema = Object.assign(
            temaDefault,
            _.get(configuracao, '[0].config', {})
          )
          this.textos = _.get(configuracao, '[0].config.textos', {})
          this.configuracao = this.getConfiguracao(
            _.get(configuracao, '[0].config.configuracao', {})
          )
          this.cardProduto = {
            ...this.cardProduto,
            meta_info: {
              ...this.getMetaInfo()
            },
            ..._.get(configuracao, '[0].cardProduto', {}),
            campos: {
              ...this.getCampo()
            }
          }
          const cupomRecomendacao =
            _.get(configuracao, '[0].cupomRecomendacao') || {}
          this.cupomRecomendacao.mensagem = cupomRecomendacao.mensagem
          if (cupomRecomendacao && cupomRecomendacao.texto) {
            this.cupomRecomendacao.texto = cupomRecomendacao.texto
          } else {
            this.cupomRecomendacao.texto = this.exemploTemplateCupom
          }
          const configTermosUso =
            _.get(configuracao, '[0].configTermosUso') || {}
          this.termosUso.versao = _.get(configTermosUso, 'versao') || 0
          this.termosUso.info = `Versão: ${this.termosUso.versao}`
          if (this.termosUso.versao) {
            const data = _.get(configTermosUso, 'data') || ''
            this.termosUso.info += data
              ? ` - criada em ${moment(data).format('DD/MM/YYYY HH:mm')}`
              : ''
          }
          const termosUso = _.get(configuracao, '[0].termosUso') || ''
          this.termosUso.conteudo = termosUso
          const politicaPrivacidade =
            _.get(configuracao, '[0].politicaPrivacidade') || ''
          this.form.politicaPrivacidade = politicaPrivacidade
          this.s3BucketRoot = _.get(configuracao, '[0].s3BucketRoot') || ''
          this.isLoading = false
          this.isLoadingImg = false
          this.getDataTextStatic()
          this.getPassosImplantacao()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getDataMenu() {
      this.isLoading = true
      this.$store
        .dispatch(TEMA_LOAD_MENU, false)
        .then(configuracao => {
          this.menu.menus = _.get(configuracao, '[0].config.menus', [])
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter os dados da configuração de menu.'
          )
        })
    },
    getDataTextStatic() {
      this.isLoading = true
      this.$store
        .dispatch(TEXTO_ESTATICO_LOAD)
        .then(data => {
          const translation = _.get(data, 'translation') || null
          if (translation) {
            const ecommerce = translation.ecommerce
            this.cardProdutoPreview.btnSobDemanda =
              ecommerce['card.produto.btn.consultor']

            this.cardProduto = {
              ...this.cardProduto,
              meta_info: {
                btn_sob_demanda: {
                  bgColor: '',
                  cor: '',
                  bold: 'false',
                  italic: 'false',
                  fontSize: {
                    app: '5',
                    commerce: '5'
                  },
                  key: 'btn_availability_alert',
                  label: 'Botão alerta de disponibilidade'
                },
                ...this.cardProduto.meta_info
              },
              campos: {
                ...this.cardProduto.campos,
                btn_sob_demanda: {
                  key: 'btn_sob_demanda',
                  label: 'Botão produto sob demanda',
                  labels: {
                    cor: 'Texto',
                    bg: 'Fundo'
                  }
                }
              }
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter os dados de texto estático.'
          )
        })
    },
    getDataStatic() {
      this.isLoading = true
      this.$store
        .dispatch(TEMA_LOAD_STATIC, false)
        .then(conteudoCustom => {
          if (conteudoCustom) {
            const {
              rodape,
              infoAdicionais,
              instrucoesPedido,
              instrucaoLogin,
              instrucaoCadastro,
              msgCadastroAnalise,
              instrucoesSubmissao
            } = conteudoCustom
            if (rodape && rodape.conteudo) {
              this.form.rodape = rodape.conteudo
            }
            if (infoAdicionais && infoAdicionais.conteudo) {
              this.form.infoAdicionais = infoAdicionais.conteudo
            }
            if (instrucoesPedido && instrucoesPedido.conteudo) {
              this.form.instrucoesPedido = instrucoesPedido.conteudo
            }
            if (instrucaoLogin && instrucaoLogin.conteudo) {
              this.form.instrucaoLogin = instrucaoLogin.conteudo
            }
            if (instrucaoCadastro && instrucaoCadastro.conteudo) {
              this.form.instrucaoCadastro = instrucaoCadastro.conteudo
            }
            if (msgCadastroAnalise && msgCadastroAnalise.conteudo) {
              this.form.msgCadastroAnalise = msgCadastroAnalise.conteudo
            }
            if (instrucoesSubmissao && instrucoesSubmissao.conteudo) {
              this.form.instrucoesSubmissao = instrucoesSubmissao.conteudo
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter os dados de conteúdo estático.'
          )
        })
    },
    getCampo() {
      return {
        nome_produto: {
          key: 'nome_produto',
          label: 'Nome do produto'
        },
        preco_venda: {
          key: 'preco_venda',
          label: 'Preço de venda'
        },
        preco_atacado: {
          key: 'preco_atacado',
          label: 'Preço de atacado'
        },
        preco_original: {
          key: 'preco_original',
          label: 'Preço original'
        },
        msg_extra: {
          key: 'msg_extra',
          label: 'Mensagem extra'
        },
        btn_adicionar: {
          key: 'btn_adicionar',
          label: 'Botão adicionar',
          labels: {
            cor: 'Texto',
            bg: 'Fundo'
          }
        },
        btn_alerta_disponibilidade: {
          key: 'btn_availability_alert',
          label: 'Botão alerta de disponibilidade',
          labels: {
            cor: 'Texto',
            bg: 'Fundo'
          }
        }
      }
    },
    getMetaInfo() {
      return {
        nome_produto: {
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: 5,
            commerce: 5
          },
          key: 'nome_produto',
          label: 'Nome do produto'
        },
        preco_venda: {
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'preco_venda',
          label: 'Preço de venda'
        },
        preco_atacado: {
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'preco_atacado',
          label: 'Preço de atacado'
        },
        preco_original: {
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'preco_original',
          label: 'Preço original'
        },
        msg_extra: {
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'msg_extra',
          label: 'Mensagem extra'
        },
        btn_adicionar: {
          bgColor: '',
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'msg_extra',
          label: 'Mensagem extra'
        },
        btn_availability_alert: {
          bgColor: '',
          cor: '',
          bold: 'false',
          italic: 'false',
          fontSize: {
            app: '5',
            commerce: '5'
          },
          key: 'btn_availability_alert',
          label: 'Botão alerta de disponibilidade'
        }
      }
    },
    onInitUpload(data) {
      this.isLoadingImg = data
    },
    onUploadSuccess() {
      setTimeout(() => {
        this.isLoadingImg = false
      }, 500)
    },
    adicionarMenu() {
      let { descricao, destino } = this.menu
      if (!destino.startsWith('http')) {
        destino = 'http:' + destino
      }
      this.menu.menus.push({ descricao, destino })
      this.submitMenus()
    },
    removerMenu({ destino }) {
      this.menu.menus = this.menu.menus.filter(i => i.destino != destino)
      this.submitMenus()
    },
    submitMenus() {
      const { menus } = this.menu
      const data = { menus }
      this.isLoading = true
      this.$store
        .dispatch(TEMA_UPDATE_MENU, data)
        .then(() => {
          this.menu.descricao = ''
          this.menu.destino = ''
          this.$vueOnToast.pop('success', 'Menu atualizado com sucesso.')
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response ||
            'Não foi possível atualizar os dados da configuração de menu.'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onSubmitTema() {
      const tema = {
        ...this.tema,
        logo: this.getFornecedorId,
        textos: this.textos,
        configuracao: this.configuracao
      }
      const configTermosUso = {
        termosUso: this.termosUso.isUpdating ? this.termosUso.conteudo : null,
        versao: this.termosUso.versao,
        isAtualizando: this.termosUso.isUpdating,
        grupo: this.getFornecedor.grupo,
        fornecedoresGrupo: this.getFornecedores
          .filter(fg => fg.grupo == this.getFornecedor.grupo)
          .map(f => f.idfornecedor)
      }
      const data = {
        tema,
        configTermosUso,
        rodape: this.form.rodape ? this.form.rodape : null,
        extraInfo: {
          content: this.form.infoAdicionais ? this.form.infoAdicionais : null,
          title: 'Informações adicionais'
        },
        cardProduto: this.cardProduto,
        cupomRecomendacao: this.cupomRecomendacao,
        politicaPrivacidade: this.form.politicaPrivacidade
          ? this.form.politicaPrivacidade
          : null,
        instrucoesPedido: this.form.instrucoesPedido
          ? this.form.instrucoesPedido
          : null,
        instrucaoLogin: this.form.instrucaoLogin
          ? this.form.instrucaoLogin
          : null,
        instrucaoCadastro: this.form.instrucaoCadastro
          ? this.form.instrucaoCadastro
          : null,
        msgCadastroAnalise: this.form.msgCadastroAnalise
          ? this.form.msgCadastroAnalise
          : null,
        instrucoesSubmissao: this.form.instrucoesSubmissao
          ? this.form.instrucoesSubmissao
          : null
      }
      this.isLoading = true
      this.$store
        .dispatch(TEMA_UPDATE, data)
        .then(() => {
          this.menu.descricao = ''
          this.menu.destino = ''
          if (this.getFornecedor.publicado) {
            this.$vueOnToast.pop('success', 'Tema salvo com sucesso.')
          } else {
            this.showWizard = 'sucesso'
            this.getPassosImplantacao()
          }
          this.isLoading = false
        })
        .catch(err => {
          const erro =
            err.response.data.Mensagem ||
            err.response.data ||
            err.response ||
            'Erro ao salvar tema.'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    onRestaurarTema() {
      const options = {
        text:
          'Ao restaurar o tema será definido como padrão as Cores, Título da página, Card de produto, Textos do Cupom e o Termo de uso!',
        title: `Deseja realmente restaurar o tema padrão?`
      }
      this.confirmSwal(options, () => this.restaurarTema())
    },
    restaurarTema() {
      this.isLoading = true
      this.$store
        .dispatch(TEMA_RESTAURAR_MENU)
        .then(() => {
          this.$vueOnToast.pop('success', 'Tema padrão aplicado com sucesso.')
          this.isLoading = false
          this.getDataTema()
          this.getDataMenu()
          this.getDataStatic()
        })
        .catch(err => {
          const erro = err.response || 'Não foi possível aplicar o tema padrão.'
          this.$vueOnToast.pop('error', erro)
          this.isLoading = false
        })
    },
    formatarPreco(templateQtdeDecimais, variavel) {
      const p = this.datasetTemplate[variavel + '_padrao'].replace(',', '.')
      return Number(p)
        .toFixed(templateQtdeDecimais)
        .replace('.', ',')
    },
    getConfiguracao(config) {
      const defaultConfiguracao = {
        tituloPagina: '',
        trackingGoogleAnalytics: '',
        habilitarCarregar: true
      }
      const habilitarCarregar =
        config.habilitarCarregar === 'false' ? false : true
      return { ...defaultConfiguracao, ...config, habilitarCarregar }
    },
    toggleField(key, field) {
      this.cardProduto.meta_info[key][field] =
        this.cardProduto.meta_info[key][field] === 'true' ? 'false' : 'true'
    },
    copyToClipboard(text) {
      const tempTextArea = document.createElement('textarea')
      tempTextArea.value = text
      document.body.appendChild(tempTextArea)
      tempTextArea.select()
      document.execCommand('copy')
      document.body.removeChild(tempTextArea)
      this.$vueOnToast.pop(
        'success',
        `Texto copiado para a área de transferência:  ${text}`
      )
    },
    updateCupomTexto(val) {
      if (!val) return ''
      this.$set(
        this.cupomRecomendacaoPreview,
        'texto',
        Mustache.render(val, this.datasetTemplate)
          .replace(/([\s\-\/]+)$/g, '')
          .replace(/([\s\-\/] [\s\-\/])/g, '')
          .replace(/&lt;br&gt;/g, '<br>')
      )
    },
    updateCupomMensagem(val) {
      if (!val) return ''
      this.$set(
        this.cupomRecomendacaoPreview,
        'mensagem',
        Mustache.render(val, this.datasetTemplate)
          .replace(/([\s\-\/]+)$/g, '')
          .replace(/([\s\-\/] [\s\-\/])/g, '')
          .replace(/&lt;br&gt;/g, '<br>')
      )
    }
  },
  watch: {
    'cardProduto.templateTitulo'(newTemplate) {
      if (!newTemplate) return ''
      this.cardProdutoPreview.nome = Mustache.render(
        newTemplate,
        this.datasetTemplate
      )
        .replace(/([\s\-\/]+)$/g, '')
        .replace(/([\s\-\/] [\s\-\/])/g, '')
        .replace(/&lt;br&gt;/g, '<br>')
    },
    'cardProduto.templateMensagemExtra'(newTemplate) {
      if (!newTemplate) return ''
      this.cardProdutoPreview.mensagemExtra = Mustache.render(
        newTemplate.replace(/\{\{/g, '{{{').replace(/\}\}/g, '}}}'),
        this.datasetTemplate
      )
        .replace(/([\s\-\/]+)$/g, '')
        .replace(/([\s\-\/] [\s\-\/])/g, '')
        .replace(/&lt;br&gt;/g, '<br>')
    },
    'cardProduto.templateQtdeDecimais'(val) {
      this.datasetTemplate.preco_varejo = this.formatarPreco(
        val,
        'preco_varejo'
      )
      this.datasetTemplate.preco_atacado = this.formatarPreco(
        val,
        'preco_atacado'
      )
      this.datasetTemplate.preco_unitario = this.formatarPreco(
        val,
        'preco_unitario'
      )
      this.cardProdutoPreview.preco = `R$ ${this.datasetTemplate.preco_atacado} UN`
      this.cardProdutoPreview.precoVarejo = `R$ ${this.datasetTemplate.preco_varejo} UN`
      this.cardProdutoPreview.precoOriginal = `R$ ${this.datasetTemplate.preco_varejo_padrao} UN`
    }
  },
  created() {
    this.getDataTema()
    this.getDataMenu()
    this.getDataStatic()
  }
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';
.border-img {
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 220px;
  min-height: 220px;
}
.border-img-cupom {
  border: 1px solid #ccc;
  border-radius: 10px;
  max-width: 300px;
}
.border-left {
  border-left: 1px solid #ccc;
}
.border-color-picker {
  border: 1px solid #ccc !important;
}
.border-red {
  border: 1px solid #ff5252 !important;
  border-radius: 5px;
  padding-top: 10px;
}
.bubble {
  border: solid 1px #e5f98e;
  border-radius: 5px;
  background: #e5f98e;
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  bottom: 110px;
  max-width: 300px;
}
</style>
